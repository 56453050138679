<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>工作台</el-breadcrumb-item>
			<el-breadcrumb-item>任务查询</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<!--搜索-->
			<el-form ref="searchFormRef"
			         :model="searchForm"
			         label-width="80px"
			         class="searchForm">
				<el-row :gutter="20">
					<el-col :span="6">
						<el-form-item label="操作类型" prop="handlerType">
							<el-select v-model.number="searchForm.handlerType"
							           placeholder="请选择"
							           @change="handlerTypeChange">
								<el-option
												v-for="item in handlerTypeList"
												:key="item.handlerType"
												:label="item.desc"
												:value="item.handlerType">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="操作编号" prop="handlerNo">
							<el-input v-model="searchForm.handlerNo"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="节点状态" prop="handlerNode">
							<el-select v-model.number="searchForm.handlerNode" placeholder="请选择">
								<el-option
												v-for="item in handlerNodeList"
												:key="item.code"
												:label="item.desc"
												:value="item.code">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="票据包号" prop="paperPagNo">
							<el-input v-model="searchForm.paperPagNo"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :offset="9" :span="3">
						<el-button type="primary" @click="getTableList">查询</el-button>
					</el-col>
					<el-col :span="3">
						<el-button @click="searchFormReset">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
			<!--列表-->
			<el-table :data="tableList" border>
				<el-table-column label="操作类型" prop="handlerType"></el-table-column>
				<el-table-column label="操作编号" prop="handlerNo"></el-table-column>
				<el-table-column label="金额（元）" prop="money"></el-table-column>
				<el-table-column label="申请人" prop="applicant"></el-table-column>
				<el-table-column label="承兑人" prop="acceptor"></el-table-column>
				<el-table-column label="票据包号" prop="paperBagNo"></el-table-column>
				<el-table-column label="到期日" prop="expireDate"></el-table-column>
				<el-table-column label="节点状态" prop="handlerNode"></el-table-column>
				<el-table-column label="操作">
					<template v-slot="scope">
						<el-button type="primary"
						           size="mini"
						           @click="goQueryDetail(scope.row)">
							查看
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页区域 -->
			<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="searchForm.pageNum"
							:page-sizes="[1, 2, 5, 10]"
							:page-size="searchForm.pageSize"
							layout="total, sizes, prev, pager, next, jumper"
							:total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
  export default {
    name: "TaskQueryList",
	  data(){
      return {
        handlerTypeList: [], // 操作类型下拉框数据
	      handlerNodeList:[], // 节点状态下拉框数据
        // 搜索表单
        searchForm: {
          handlerType: '', // 操作类型
          handlerNo: '', // 操作编号
          handlerNode: '', // 节点状态
          paperPagNo  :'', // 票据包号
          pageNum: 1,
          pageSize: 10
        },
        total: 0, // 总条数
	      tableList: [] // 列表数据
      }
	  },
	  mounted(){
      // 获取操作类型数据
		  this.getHandlerType()
      // 获取列表数据
		  this.getTableList()
	  },
		methods: {
      // 获取操作类型数据
      async getHandlerType() {
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/workbench/task/query/handlerType')
        if (res.code !== 200) return this.$message.error(res.msg);
        this.handlerTypeList = res.data
      },
			// 监听操作类型选中值变化 并  获取节点状态数据
      async handlerTypeChange(value){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/workbench/task/query/handlerNode/${value}`)
        if(res.code !== 200) return this.$message.error(res.msg)
        this.handlerNodeList = res.data
      },
			// 获取列表数据
			async getTableList(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/workbench/task/query/page',{params: this.searchForm});
        console.log(res)
        if(res.code !== 200) return this.$message.error(res.msg);
        this.total = res.data.total
        this.tableList = res.data.records
			},
		 // 重置搜索表单
		 searchFormReset(){
		   this.$refs.searchFormRef.resetFields()
			 this.getTableList()
		 },
		 // 查看详情
      goQueryDetail(row){
		    this.$router.push({name: 'taskQueryDetail', query:{
            ticketId: row.ticketId,
				    handlerTypeCode: row.handlerTypeCode
        }})
		  },
		 // 监听pagesize改变的事件
		 handleSizeChange(newSize){
		   this.searchForm.pageSize = newSize;
		   this.getTableList()
		 },
		 //监听 页码值 改变的事件
		 handleCurrentChange(newPage){
		   this.searchForm.pageNum = newPage;
		   this.getTableList()
		 },
		}
  }
</script>

<style lang="less" scoped>
	.searchForm{
	.el-select{
		width: 100%;
	}
	.el-button{
		width: 100%;
	}
	}
	.el-table{
		margin: 20px 0;
	}
</style>